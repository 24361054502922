<template>
  <tr :class="{ 'text-danger': canGetFullReport && entry.future_time === '1' }">
    <td>{{ entry.project }}</td>
    <td>{{ entry.version }}</td>
    <td>
      <clink :to="href" :text="true">{{ entry.issue }}</clink>
    </td>
    <td class="text-nowrap">{{ doneRatio }}</td>
    <td>{{ entry.resource }}</td>
    <td class="text-nowrap d-flex align-items-center justify-content-center">
      <IconCalendar
        class="mr-1 text_link"
        @click="$emit('openEntry', entry.id)"
        ref="openInModal"
      />
      <clink :to="{ name: 'calendar', params: { timeEntryId: entry.id } }">
        <span ref="openInCalendar">
          {{ entry.spent_on }}
        </span>
      </clink>
    </td>
    <td class="text-right">{{ entry.hours }}h</td>
    <td ref="comments">
      <icon-comment v-if="entry.comments" />
    </td>
  </tr>
</template>

<script>
import tippy from "tippy.js";
import IconComment from "@/icons/comment-alt-solid.svg";
import IconCalendar from "@/icons/calendar-solid.svg";

export default {
  props: ["entry", "canGetFullReport"],
  components: {
    IconComment,
    IconCalendar,
  },
  computed: {
    href() {
      return {
        name: "tree",
        params: {
          type: "issue",
          id: this.entry.issue_id,
        },
      };
    },
    doneRatio() {
      const before = this.entry.done_ratio_before || this.entry.done_ratio;
      const after = this.entry.done_ratio_after || this.entry.done_ratio;
      return (
        after +
        "%" +
        (after != before
          ? ` (${after > before ? "+" : ""}${after - before})`
          : "")
      );
    },
  },
  mounted() {
    if (this.entry.comments) {
      tippy(this.$refs.comments, {
        content: this.entry.comments,
      });
    }
    tippy(this.$refs.openInModal, {
      content: "Time entry",
    });
    tippy(this.$refs.openInCalendar, {
      content: "Time entry in ind. planning",
    });
  },
};
</script>
