import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import FlatPickr from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";
import dayjs from "dayjs";
import * as Config from "@/config/constants";
import { processXlsxResponse, userIsAtLeastCP, userIsMemberOfProject } from "@/app/helpers";
import CramLine from "./CramLine.vue";
import VersionSelect from "@/views/common/VersionSelect.vue";
import Checkbtn from "@/views/common/Checkbtn.vue";
import IconDownload from "@/icons/download-solid.svg";

// @ts-ignore
const TimeModal = () => import(/* webpackChunkName: "timemodal" */ "@/views/time-modal/TimeModal.vue");

function formatFilters(filters) {
    return {
        projectId: filters.project?.id,
        versionIds: filters.versions ? filters.versions.map(v => v.id) : null,
        resourceId: filters.resource?.id,
        versionType: filters.versionType,
        from: filters.from,
        to: filters.to,
        onlyPlanned: filters.onlyPlanned ? '' : null,
    };
}

function defaultFilters() {
    return {
        project: null,
        versions: null,
        versionType: null,
        resource: null,
        from: '',
        to: dayjs().format(Config.DATE_FORMAT),
        onlyPlanned: false,
    };
};

export default {
    name: "Cram",
    components: {
        Multiselect,
        FlatPickr,
        CramLine,
        TimeModal,
        IconDownload,
        VersionSelect,
        Checkbtn,
    },
    data: () => {
        return {
            filters: defaultFilters(),
            dateConfig: {
                locale: French,
            },
            versions: [],
            timeEntries: [],
            fullSum: 0,
            futureSum: 0,
            timeEntryId: null,
            group: null,
        };
    },
    computed: {
        // @ts-ignore
        ...mapGetters({
            user: "Resource/auth/user",
            resources: "Resource/list/resources",
            versionTypes: "Enumeration/customFields/versionTypes",
            groups: 'Group/edit/groups',
        }),
        projects() {
            const projects = this.$store.getters["Project/list/projects"];
            if (this.user.admin) return projects;
            return projects.filter((project) =>
                userIsMemberOfProject(this.user, project)
            );
        },
        canGetFullReport() {
            return this.filters.project || this.filters.resource || this.filters.versionType;
        },
        canSeeExcelPlus() {
            return userIsAtLeastCP(this.user);
        },
        fullSumCell() {
            if (!this.fullSum) return '';
            return ((Math.round(this.fullSum * 100) / 100) || 0) + ' h';
        },
        futureSumCell() {
            if (!this.futureSum) return '';
            return ((Math.round(this.futureSum * 100) / 100) || 0) + ' h';
        },
        resourcesFiltered() {
            if (!this.group) return this.resources;
            const resourceIds = this.group.userIds.map(id => parseInt(id));
            return this.resources.filter(resource => resourceIds.includes(resource.id));
        }
    },
    methods: {
        selectProject() {
            this.filters.versions = null;
            if (!this.filters.project) return (this.versions = []);
            const projectId = this.filters.project.id;
            this.$store
                .dispatch("Issue/list/getVersions", projectId)
                .then((versions) => {
                    this.versions = versions;
                });
        },
        exportFile(advanced = false) {
            if (!this.canGetFullReport) return;
            const filters: any = formatFilters(this.filters);
            if (advanced) filters.advanced = advanced;
            this.$store
                .dispatch("TimeEntry/list/getReportFile", filters)
                .then(processXlsxResponse("CRAM.xlsx"));
        },
        exportFileStandard() {
            return this.exportFile();
        },
        exportFilePlus() {
            return this.exportFile(true);
        },
        refreshTimeEntries() {
            this.fullSum = 0;
            this.futureSum = 0;
            this.$store.dispatch("TimeEntry/list/getReport", formatFilters(this.filters))
                .then(timeEntries => {
                    this.timeEntries = timeEntries;
                    if (this.canGetFullReport) {
                        this.$store.dispatch("TimeEntry/list/sumReport", formatFilters(this.filters))
                            .then(data => {
                                this.fullSum = data.full_sum;
                                this.futureSum = data.future_sum;
                            });
                    }
                });
        },
        resetFilters() {
            this.filters = defaultFilters();
            this.versions = [];
        },
        selectGroup(group) {
            if (this.group === group) return this.group = null;
            this.group = group;
        },
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                this.refreshTimeEntries();
            }
        },
    },
    mounted() {
        this.$store.dispatch("Project/list/getList");
        this.$store.dispatch("Enumeration/customFields/getList");
        this.refreshTimeEntries();
    },
};
