<template>
  <button
    :class="[
      'btn',
      value ? 'btn-secondary' : 'btn-outline-secondary',
      'shadow-none',
    ]"
    @click="toggle"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "Checkbtn",
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      mutableValue: this.value,
    };
  },
  methods: {
    toggle() {
      this.mutableValue = !this.mutableValue;
      this.$emit("input", this.mutableValue);
    },
  },
  watch: {
    value() {
      this.mutableValue = this.value;
    },
  },
};
</script>
